/* #region main article */
/* ------------------------------------------------------------------------*/
#main {
    position: relative;
    z-index: 100;
}

.ui-page-content {
    font-size: var(--font-size-body1);
    line-height: 1.43;
    margin-bottom: 30px;
}

.ui-page-content .article {
    font-size: var(--font-size-body1);
    line-height: 1.43;
    max-width: 730px;
    margin: 0 auto;
    clear: both;
    border-top: 5px solid #e8e8e8;
}

.ui-page-content .title {
    font-size: var(--font-size-h3);
    line-height: 1.17;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

/* reset margin and padding */
.ui-page-content .article:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
}

.w-menu + .w-content .title:first-child,
.w-menu + .w-content img:first-child {
    margin-top: 0;
    padding-top: 0;
}

.ui-page-content h1.title-others {
    font-size: var(--font-size-heading1);
    line-height: 1.14;
    font-family: SansOri, SansOriFallback, sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    padding: 21px 0 15px 0;
}

.ui-page-content .intro {
    font-size: var(--font-size-subtitle1);
    line-height: 1.29;
    padding: 0;
    text-align: center;
    text-justify: inter-word;
}

.ui-page-content .content {
    font-size: var(--font-size-body1);
    line-height: 1.57;
    text-align: left;
    text-justify: inter-word;
}

.ui-page-content p {
    text-indent: 0;
    margin: 0 0 15px;
}

.ui-page-content .heading {
    text-transform: uppercase;
    font-size: var(--font-size-h5);
    padding: 0;
    margin-bottom: 20px;
}

.ui-page-content .publish-date {
    margin-bottom: 0;
    font-size: var(--font-size-caption);
}

.ui-page-content .article .w-box,
.ui-page-content .article .ui-product-box,
.ui-page-content.news-archive .w-box,
.ui-page-content.news-archive .ui-product-box {
    float: right;
    width: 180px;
    margin: 0 0 15px 15px;
}

.ui-page-content .article .w-box,
.ui-page-content.news-archive .w-box {
    padding: 12px;
    margin-bottom: 20px;
}

.ui-page-content .article .ui-product-box,
.ui-page-content.news-archive .ui-product-box {
    padding-left: 0;
    padding-right: 0;
    width: 215px;
    margin-bottom: 0;
}

.ui-page-content .article .ui-wide-image-box,
.ui-page-content.news-archive .ui-wide-image-box {
    float: none;
    width: 100%;
    margin-left: 0;
}

.ui-page-content .article ul,
.ui-page-content .article ol,
.ui-page-content.news-archive ul,
.ui-page-content.news-archive ol {
    margin-left: 10px;
    margin-bottom: 15px; /* added to follow logic of p element */
    padding-left: 10px;
}

.ui-page-content .article .w-box ul,
.ui-page-content .article .w-box ol,
.ui-page-content.news-archive .w-box ul,
.ui-page-content.news-archive .w-box ol {
    margin-left: 0;
    padding-left: 0;
}

.ui-page-content .columns {
    -moz-column-count: 2; /* Firefox */
    -webkit-column-count: 2; /* Safari and Chrome */
    column-count: 2;
    -moz-column-gap: 30px; /* Firefox */
    -webkit-column-gap: 30px; /* Safari and Chrome */
    column-gap: 30px;
}

/* TODO: The link styling should be done in a better way via page-editor link customization.
The link should has own specific class ( e.g. highlighted-link, common-link, etc. ). */
.w-editorial article .content a,
.ui-page-content article p a,
.ui-press-content article .content p a,
.ui-page-content.news-archive p a {
    text-decoration: underline;
}

.w-editorial article .content a.common-link:hover,
.ui-page-content article .content p a.common-link:hover,
.ui-page-content article .columns p a.common-link:hover,
.ui-page-content.news-archive .content p a.common-link:hover,
.ui-page-content.news-archive .columns p a.common-link:hover,
.ui-press-content article .content p a.common-link:hover {
    text-decoration: none;
}

.ui-page-content .inset-box p {
    margin-bottom: 7px;
}

.ui-article-content img {
    margin-top: 16px;
}

.ui-page-content.news-archive .headline {
    margin: 30px 0;
}

.js-content-container {
    overflow: hidden;
}
/* #endregion main article */

/* BP1 and BP2 */
@media screen and (max-width: 767px) {
    /* #region main article */
    /* ------------------------------------------------------------------------*/
    .ui-page-content {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .ui-page-content .title {
        font-size: var(--font-size-h4);
        line-height: 1.2;
    }

    .ui-page-content .columns {
        -moz-column-count: 1; /* Firefox */
        -webkit-column-count: 1; /* Safari and Chrome */
        column-count: 1;
        -moz-column-gap: 0; /* Firefox */
        -webkit-column-gap: 0; /* Safari and Chrome */
        column-gap: 0;
    }

    .ui-page-content.news-archive .headline {
        margin: 10px 0;
    }

    /* #endregion main article */

    .ui-registration-form .k-multiselect-wrap .k-button {
        width: auto;
    }
}
