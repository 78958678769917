/* Typography
---------------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    line-height: 1.25;
}

/* mobile 1st now */
.h1 {
    font-size: var(--font-size-h1);
}

.h2 {
    font-size: var(--font-size-h2);
}

.h3 {
    font-size: var(--font-size-h3);
}

.h4 {
    font-size: var(--font-size-h4);
}

.h5 {
    font-size: var(--font-size-h5);
}

.h6 {
    font-size: var(--font-size-h6);
}

.p {
    font-size: var(--font-size-body1);
    line-height: 1.36;
}

.p-small {
    font-size: var(--font-size-caption);
    line-height: 1.42;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

h1.headline,
.h1.headline,
.headline h1 {
    text-align: center;
    margin: 0 0 22px;
    font-weight: bold;
    font-family: SansOri, SansOriFallback, sans-serif;
}

h2.headline,
h3.headline,
.headline h2,
.headline h3 {
    font-family: SansOri, SansOriFallback, sans-serif;
    text-align: center;
}

h4.headline,
h5.headline,
h6.headline,
.headline h4,
.headline h5,
.headline h6 {
    font-family: SansOri, SansOriFallback, sans-serif;
    text-align: left;
}

/* Headline separator
-------------------------------------------------------------------------------*/
.headline-separator-holder {
    position: relative;
    z-index: 1;
    clear: both;
    overflow: hidden;
    text-align: center;
    padding: 0;
    margin: 40px auto 20px;
    text-transform: none;
    overflow-wrap: break-word;
}

.headline-separator-holder.has-border {
    border-bottom: 4px solid var(--grey-100);
    padding-bottom: 15px;
}

.headline-separator {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 2rem;
    line-height: 1.25;
    font-weight: bold;
    color: var(--grey-700);
}

h1 .headline-separator {
    font-size: 2.4rem;
}

/** ori-ui breakpoint for wider screens */
@media (min-width: 900px) {
    .h1 {
        font-size: var(--font-size-h1-desktop);
    }

    .h2 {
        font-size: var(--font-size-h2-desktop);
    }

    .h3 {
        font-size: var(--font-size-h3-desktop);
    }

    .h4 {
        font-size: var(--font-size-h4-desktop);
    }

    .h5 {
        font-size: var(--font-size-h5-desktop);
    }

    .h6 {
        font-size: var(--font-size-h6-desktop);
    }
}

/* BP 1 - 2 */
@media screen and (max-width: 767px) {
    h1.headline,
    .h1.headline,
    .headline h1 {
        font-size: var(--font-size-h4); /* was 2.5rem */
        margin: 0 0 10px;
    }

    h1.headline-serif,
    .headline-serif h1 {
        font-size: var(--font-size-h1); /* was 3.2rem */
    }

    h2.headline,
    .headline h2 {
        font-size: var(--font-size-h5); /* was 2rem */
    }

    h3.headline,
    .headline h3 {
        font-size: var(--font-size-h6); /* was 1.6rem */
    }
}

/* BP 2 */
@media screen and (min-width: 480px) and (max-width: 767px) {
    .headline-separator {
        font-size: 3rem;
    }
}

/* BP 3 */
@media screen and (min-width: 768px) {
    .headline-separator-holder {
        margin: 60px auto 30px;
    }

    .headline-separator {
        font-size: 4.5rem;
    }
    
    h1 .headline-separator {
        font-size: 3rem;
    }
}
