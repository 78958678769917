/*
===============================================================================
DEFAULT.CSS
-------------------------------------------------------------------------------
The default.css contains main layout classes, common elements and modules
definitions.
===============================================================================
*/

/* Additional CSS var definitions. */
:root {
    /*
    Most-used font sizes for the widget icons with 10 or more use cases.
    Could be defined based on body or input font size.
    */
    --font-size-icon1: 1.6rem; /* Was 16px icon for input font size of 14px originally (i.e. cca 1.143 * body or input font size). */
    --font-size-icon2: 2rem;
    --font-size-icon3: 2.2rem;
    --font-size-icon4: 2.4rem;
    --font-size-icon5: 2.5rem;
    --font-size-icon6: 2.8rem;
    --font-size-icon7: 2.9rem;
    --font-size-icon8: 3rem;
    --font-size-icon9: 4rem;

    /*
    Additional heading font sizes for cases where the difference from current ori-ui size is too big (5px or similar).
    TODO: discuss with designers if it is OK to go with 3rem / --font-size-h1 for these cases.
    */
    --font-size-heading1: 3.5rem;
    --font-size-heading2: 4rem;
    --font-size-heading3: 4.5rem;
}

body {
    position: relative;
}

/* n-content */
@media (min-width: 1025px) {
    .n-content {
        max-width: 984px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* My pages content area */
.page-mypages body {
    min-width: 320px;
}

.page-mypages .w-content {
    width: 100%;
}

.page-mypages .n-content {
    margin-top: 30px;
    -webkit-overflow-scrolling: touch;
}

@media (min-width: 1025px) {
    .page-mypages .n-content .w-grid {
        min-width: 100%;
    }

    .page-mypages .n-content {
        margin-left: 30px;
        margin-right: 30px;
        border-left: 280px solid transparent;
    }
}

@media (min-width: 1604px) {
    .page-mypages .n-content {
        margin-left: auto;
        margin-right: auto;
        min-width: 984px;
        border-right: 280px solid transparent;
    }
}


/* #region Default settings for section wrappers */
/* ------------------------------------------------------------------------*/

/* #region page header */

/*Vertical rhythm direction top-bottom for all pages*/
.page-header {
    margin: 0 auto;
    position: relative;
    display: block;
    border-bottom: 1px solid var(--grey-300);
    z-index: 6002; /* higher than .ui-mini-search with 6001 for breakpoints 1-4; In breakpoint 5 it has the default - 6001 */
    height: 44px; /* same height as sticky bar in responsive */
}

.page-header .w-grid {
    overflow: visible;
    padding: 0;
}

.htmlf .page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    border: none;
    min-height: 31px;
}

/* used for google ads image */
.htmlf img[width="1px"] {
    position: absolute;
    top: 0;
}

/* #endregion page header */

.htmlf .htmlf-wrapper iframe {
    margin: 0 auto;
    width: 100%;
    min-height: 400px;
}

/* Page editor ribbon */
#scWebEditRibbon {
    z-index: 10000;
}

.w-menu {
    margin-top: 0;
    float: left;
    position: relative;
    overflow: visible;
    margin-top: 0;
}

.filter-menu-trigger,
.ui-facet-navigation .facets-submit {
    display: none;
}

.w-main {
    margin: 0 auto 16px;
    overflow: hidden;
    position: relative;
    display: block;
}

.htmlf-wrapper.has-sticky-header {
    padding-top: 46px;
    box-sizing: content-box;
}
.n-header ~ .htmlf-wrapper.has-sticky-header {
    padding-top: 0;
}

.htmlf-wrapper.w-grid {
    overflow: hidden;
    max-width: none;
    width: 100%;
}

.wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: auto;
}

.w-aside-column {
    margin-left: 0;
}

/* #endregion Default settings for section wrappers */

/*
===============================================================================
   COMMON PAGE ELEMENTs
===============================================================================
*/

html,
body,
button,
input,
optgroup,
select,
textarea,
.k-tooltip-content {
    font-family: SansOri, SansOriFallback, sans-serif;
}

/* #region text styles */
/* ------------------------------------------------------------------------*/

.text-small {
    font-size: var(--font-size-caption);
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.text-uppercase {
    text-transform: uppercase;
}

/*Text position*/
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

/* word break */
.word-break {
    word-break: break-word;
}

/* #endregion text styles */

/* #region common float styles */
/* ------------------------------------------------------------------------*/

.float-left {
    float: left;
}
.float-right {
    float: right;
}

/* #endregion common float styles */

/* #region common link styles */
/* ------------------------------------------------------------------------*/

a {
    color: var(--text-primary);
    text-decoration: none;
}

a:hover,
a:focus,
.active {
    color: var(--text-hover);
}

.active {
    font-weight: bold;
}

.link:hover {
    cursor: pointer;
}

.link:hover,
.link:focus,
.link.active {
    color: var(--text-hover);
}

.highlighted-link {
    color: var(--text-primary);
    text-decoration: underline;
}

.highlighted-link:hover,
.highlighted-link:focus {
    color: var(--text-hover);
    text-decoration: underline;
}

.ui-link,
.highlighted-link,
.common-link {
    cursor: pointer;
}

.common-link {
    font-size: var(--font-size-caption);
    line-height: 1.42;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 2px solid;
    display: inline-block;
    padding-bottom: 2px;
    margin-bottom: 15px;
}

.common-link:hover {
    color: var(--text-hover);
}

/* #endregion common link styles */

/* #region Button wrappers */
.w-controls:last-of-type,
.w-buttons:last-of-type {
    padding: 0 5px; /* space buffer for .k-button box-shadow so it is not cut due to overflow: hidden applied on many wrappers */
}

/* #endregion Button wrappers */

.flex-row {
    align-items: center;
    display: flex;
    flex-flow: row;
}

/* Hidden labels for SEO & page readers */
.audible {
    position: absolute !important;
    left: -999em !important;
    top: 0;
    width: 1px;
    height: 1px;
}

/* #region common list styles */
/* ------------------------------------------------------------------------*/

.icon-arrow {
    display: inline-block;
    padding: 2px 8px 2px 18px;
}

.inline-list li {
    list-style: none;
    display: inline;
}

.links-list {
    list-style: none;
}

/* #endregion common list styles */

/* #region common errors ( for exceptions formating ) */
/* ---------------------------------------------------------------- */

.w-error {
    display: block;
    border: 1px dashed #cb0252;
    margin: 10px;
    padding: 10px 10px 0;
    font-size: var(--font-size-caption);
}

.error-caption {
    background: transparent;
    font-size: var(--font-size-subtitle1);
    font-weight: bold;
}

.exception {
    font-weight: bold;
    color: #a40243;
    text-shadow: 1px 1px 0 var(--common-white);
}

.toggle-exception-details {
    cursor: pointer;
}

.showExceptionDetails {
    font-weight: bold;
    cursor: pointer;
}
/* #endregion common errors */

/* #region generic iframe wrapper */
/* ------------------------------------------------------------------------*/
.iframe-scrollbar-wrapper {
    margin: 0;
    width: 100%;
}

.w-iframe {
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
}

.w-iframe.new-tab {
    margin: 0;
}

.totara-iframe-wrapper {
    height: 680px;
    margin: 0 auto 16px;
    width: 100%;
    display: block;
}

@media (min-width: 1025px) {
    .page-mypages .totara-iframe-wrapper {
        border-radius: 4px;
        overflow: hidden;
        background-clip: padding-box;
    }
}
/* #endregion generic iframe wrapper */
/*
===============================================================================
   PAGE MODULES
   ( organized from generic to special ones, from simple to more complex,
   from top to bottom of the page when possible )
===============================================================================
*/
/* #region default spinner holder */
/* ------------------------------------------------------------------------*/
.ui-spinner {
    display: none;
    background-color: rgba(255, 255, 255, 0.75);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1475; /* this must be under the sticky bar */
}

.ui-spinner.transparent {
    background-color: rgba(255, 255, 255, 0.75);
}

.ui-spinner.empty-page {
    overflow: hidden;
    display: block;
}
/* #endregion default spinner holder */

/* #region popup window */
.popup-content {
    padding: 0;
    height: 150px;
    overflow-y: auto;
}

.popup-content .ui-logout-form {
    margin: 0;
}

.popup-content .ui-logout-form .header {
    margin: 0 0 10px;
}

.popup-content .ui-logout-form .w-buttons {
    margin: 20px 0 0 0;
}
/* #endregion popup window */

/* #region Royal slider */
/* ------------------------------------------------------------------------*/

.rsArrow {
    cursor: pointer;
    display: block;
    height: 100%;
    position: absolute;
    width: 40px;
    z-index: 18;
    top: 0;
    padding: 0 5px;
}

.rsArrowLeft {
    left: 0;
}

.rsArrowRight {
    right: 0;
}

.rsArrowIcn {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 50%;
    background: var(--common-white);
}

.rsArrowLeft .rsArrowIcn::before,
.rsArrowRight .rsArrowIcn::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    line-height: 1.5;
    font-size: var(--font-size-icon6);
    text-align: center;
    background: none;
    color: var(--text-primary);
}

.image-icons .rsArrowIcn::before {
    background-repeat: no-repeat;
    top: 18%;
    left: 30%;
}

.rsArrow:hover .rsArrowIcn {
    opacity: 1;
}

.rsArrowDisabled .rsArrowIcn {
    opacity: 0.2;
}

/* by default the navigation is inside and no bottom margin */
.rsNav.rsBullets {
    bottom: 15px;
    height: 12px;
    line-height: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0;
}
/* for product sliders etc. the navigation is outside contents */
.rsWithBullets.nav-outside {
    overflow: visible;
    margin-bottom: 28px;
}

/* navigation item slightly bigger for better UX */
.rsNavItem.rsBullet {
    cursor: pointer;
    width: 8px;
    height: 8px;
    padding: 2px;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    background: transparent;
    border-radius: 50%;
    transition: background 0.3s ease-in-out;
}

.rsNavItem.rsBullet span {
    display: block;
    width: 8px;
    height: 8px;
    background: var(--grey-200);
    border-radius: 50%;
}

.rsNavItem.rsBullet:hover,
.rsNavSelected.rsBullet {
    background: var(--secondary-main);
}

.rsNavItem.rsBullet:hover span,
.rsNavSelected.rsBullet span {
    background: var(--secondary-main);
}

.rsNavSelected {
    cursor: default;
}

.ui-product-list .rsContent {
    padding-top: 10px;
}
/* #endregion Royal slider */

/* #region pbs bar */
/* ------------------------------------------------------------------------*/
.pbs-link-container {
    padding: 10px 0;
    margin: 0;
    position: absolute;
    top: 45px;
    background: var(--grey-50);
    border-bottom: solid 1px var(--grey-300);
    width: 100%;
    text-align: center;
    display: none;
}

.pbs-link-container .name {
    text-decoration: underline;
}

.pbs-link-container .v-icon-cross {
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
    font-style: normal;
}

.w-burger-menu-pbs {
    position: absolute;
    top: 45px;
    left: 0;
    height: 1px;
    background: transparent;
    z-index: 9500;
    width: 100%;
}

.w-burger-menu-pbs .pbs-link-container {
    margin: 0;
    top: 1px;
    background: transparent;
}

.w-burger-menu-pbs .v-icon-cross {
    display: none;
}

/* #endregion pbs bar*/

/* #region burger menu */
/* ------------------------------------------------------------------------*/
.page-overlay {
    display: none;
}

.burgermenu-opened,
.animating {
    overflow: hidden;
    height: 100%;
    width: 100%;
}
/*
can't be used, as it hides burger menu even if not in x axis ???
.burgermenu-opened body,
.animating body { overflow-y : hidden; }
*/
.burgermenu-opened .page-overlay,
.animating .page-overlay {
    display: block;
}

/* burger menu navigation switcher */
.burger-menu-trigger {
    cursor: pointer;
    font-size: 2.2rem;
    padding-left: 0;
}

/* burger menu popup layer */

.w-burger-menu-pbs .pbs-link-container {
    background: var(--common-white);
    background: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(0, var(--grey-a200)),
        color-stop(6px, var(--common-white)),
        color-stop(100%, var(--common-white))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        right,
        var(--grey-a200) 0,
        var(--common-white) 6px,
        var(--common-white) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: linear-gradient(
        to left,
        var(--grey-a200) 0,
        var(--common-white) 6px,
        var(--common-white) 100%
    ); /* W3C */
}

/* burger login form, forgot password dialogue */
.login-iframe {
    display: block;
    width: 95%;
    margin: 0 auto;
}

.forgot-password-iframe {
    display: block;
    width: calc(100% - 30px);
    margin: 0;
    padding: 15px;
}

.forgot-password-close {
    position: absolute;
    top: 7px;
    right: 14px;
    font-size: var(--font-size-subtitle1);
    cursor: pointer;
    z-index: 10;
}

.forgot-password-close.v-icon-arrow-left-thin {
    right: auto;
    left: 14px;
}
/* burger menu forgot passworf link */
.form-actions .forgot-password-link {
    cursor: pointer;
}

.form-actions .forgot-password-link::before {
    padding-right: 0.4em;
    position: relative;
    top: 0;
}

/* personal info in root user tab */

.ui-personal-info .photo {
    float: left;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 2px solid var(--common-white);
    margin-right: 0.8em;
    background: var(--grey-a400);
    border-radius: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
}

.ui-personal-info .name {
    display: block;
    margin: 5px 0 2px 0;
    font-size: var(--font-size-body1);
    line-height: 1.14;
}

.ui-personal-info .title {
    display: block;
    color: var(--text-disabled);
    font-size: var(--font-size-caption);
    line-height: 1.17;
    text-transform: uppercase;
}

.social-profile-photo {
    float: left;
    padding-right: 15px;
    width: 50px;
    height: auto;
}

.social-profile-photo img {
    height: auto;
    max-height: 60px;
    max-width: 100%;
}

/* #endregion burger menu */

/* #region tooltip box - popup menus in sticky bar */
/* ------------------------------------------------------------------------*/
.k-tooltip-content {
    text-align: center;
    padding: 12px 20px 11px;
    line-height: 1.6;
}
/* #endregion tooltip box - popup menus in sticky bar */

/* #region JS error state */
/* ------------------------------------------------------------------------*/

.async-load-error {
    padding: 5px;
    margin-bottom: 10px;
    position: absolute;
    left: -999em;
    top: 0;
    background-color: #f00;
    border-radius: 5px;
    font-size: var(--font-size-body1);
}

.async-load-error.error-on {
    position: static;
    left: auto;
    top: auto;
    clear: both;
}

.async-load-error.error-fired {
    background-color: transparent;
    -webkit-transition: background-color 1s; /* older Android browser */
    transition: background-color 1s;
}
/* #endregion JS error state */

/* #region rating */
/* ------------------------------------------------------------------------*/

.ui-rating {
    padding-top: 5px;
    overflow: hidden;
}

.ui-rating .average-rating {
    width: 90px;
    height: 17px;
    overflow: hidden;
    float: left;
    position: relative;
    margin: 0 10px 0 0;
    background: url("../Images/stars.svg") repeat-x left top;
    text-indent: -999em;
}

.ui-rating .stars {
    position: absolute;
    left: 0;
    top: 0;
    height: 17px;
    background: url("../Images/stars.svg") repeat-x left -17.5px;
}

/* #endregion rating */

/* #region filter */
/* ------------------------------------------------------------------------*/

.no-results {
    font-size: var(--font-size-body1);
    font-weight: 600;
}

/* #endregion filter */

/* #region video panel */
/* ------------------------------------------------------------------------*/

.ui-video .youtube,
.ui-video .youku {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 1.25em;
    background-color: var(--common-black);
}

.ui-video .youtube iframe,
.ui-video .youtube img,
.ui-video .youku iframe,
.ui-video .youku img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.ui-video-mp4 {
    width: 100%;
}

.ui-video.ui-video-vertical .youtube {
    margin: 0 auto;
    padding-bottom: 177.78%; /* 9:16 */
}

@media screen and (min-width: 768px) {
    .ui-video.ui-video-vertical .youtube {
        max-width: 40%;
        padding-bottom: 71.11%;
    }
}

/* #endregion video panel */

/* Related articles
---------------------------------------------------------------------------*/

.ui-related-articles .heading {
    border-color: #e8e8e8;
}

/* #region image wrappers */
/* ------------------------------------------------------------------------*/

.w-box.ui-image-box,
.w-box.ui-wide-image-box {
    padding: 0;
}

.ui-image-box img,
.ui-wide-image-box img {
    display: block;
    width: 100%;
    height: auto;
}
/* #endregion image wrappers */

/* #region tabs; TODO : merge with Kendo CSS or remove completely */
/* ---------------------------------------------------------------------------*/
.ui-tabs .menu-slider {
    border-bottom: 4px solid var(--grey-100);
    position: relative;
}

.ui-tabs .active-tab-stripe {
    content: "";
    position: absolute;
    bottom: -4px;
    height: 4px;
    background-color: var(--secondary-main);
    transition: left 0.3s, width 0.3s;
}

.ui-tabs .tab-links,
.ui-tabs .tab-heading {
    list-style: none;
    font-size: var(--font-size-h4);
    line-height: 1.12;
    color: var(--grey-300);
    font-weight: bold;
}

.ui-tabs .tab-links {
    text-align: center;
}

.ui-tabs .tab-heading {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 22px 15px;
    margin: 0 8px;
    cursor: pointer;
}

.ui-tabs .tab-links .tab-heading:hover,
.ui-tabs .tab-links .tab-heading:focus {
    color: var(--secondary-main);
}

.ui-tabs .tab-links .tab-heading.active {
    color: var(--grey-700);
}

.ui-tabs .tab .tab-heading {
    text-align: center;
    max-width: none;
    margin: 0;
    min-height: 25px;
}

.ui-tabs .tab-links .tab-heading:only-child {
    color: var(--grey-700);
    font-weight: normal;
    cursor: default;
}

.ui-tabs .tab-links .tab-heading.active:only-child::after {
    content: none;
}

.ui-tabs .tab {
    display: none;
    clear: both;
    font-weight: normal;
}

.ui-tabs .tab.active {
    display: block;
}

.ui-tabs .tab.active.ui-product-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ui-tabs .tab.active .tab-content {
    color: var(--text-primary);
}

.ui-tabs .show-more-wrap {
    overflow: hidden;
    position: relative;
}

.ui-tabs .show-more-btn {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    cursor: pointer;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
    background: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
}

.ui-tabs .show-more-text {
    font-weight: bold;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
}

.ui-tabs .tabs-separately {
    border-width: 0;
}

.ui-tabs .tabs-separately .tab {
    display: block;
}

.ui-tabs .tabs-separately .heading {
    clear: both;
    margin: 40px 0 20px;
    padding-bottom: 12px;
    text-align: center;
    border-bottom: 1px solid var(--grey-300);
}

.ui-tabs .tabs-separately .heading:first-child {
    margin-top: 0;
}

/* #endregion tabs */

/* Some generic definition for banners.
According to latest feedback, whole banner area should be a link.
Inside the show more section should be formated as common link.
Only this particular part gets highlighted on hover.
Otherwise the generic common link behavior should be used - green on hover, no underline.
---------------------------------------------------------------------------*/
.banner:hover,
.banner:focus {
    color: var(--text-primary);
}

a.banner {
    display: block;
    text-decoration: none;
}

.banner .common-link:hover,
.banner .common-link:focus {
    text-decoration: none;
    color: var(--text-hover);
}

/* #region sort by */
/* ------------------------------------------------------------------------*/
.ui-sort-by {
    width: auto;
    float: right;
    margin: 12px 0px 0 0;
    padding: 0;
}

.ui-sort-by fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

.ui-sort-by label {
    position: relative;
    top: 3px;
    margin-right: 10px;
}

.ui-sort-by .k-combobox {
    float: right;
    width: 110px;
}

.ui-sort-by .k-input {
    font-size: var(--font-size-overline);
    padding: 0;
    min-height: 20px;
}

.ui-sort-by .k-select {
    margin-top: -10px;
    width: 22px;
    margin-left: -23px;
}
/* #endregion sort by */

/* #region search suggestions and results */
.spell {
    font-size: var(--font-size-caption);
    line-height: 1.17;
    padding: 6px 10px;
    margin: 0;
    color: var(--text-primary);
    border-bottom: 1px solid var(--grey-300);
}

.no-search-results {
    margin: 10px;
    font-size: var(--font-size-caption);
}

/* Search results ordering and pagination
---------------------------------------------------------------------------*/
.w-result-summary {
    clear: both;
    overflow: hidden;
    border-top: 1px solid var(--grey-300);
    border-bottom: 1px solid var(--grey-300);
    text-align: right;
    min-height: 34px;
    margin: 11px 0;
    padding: 0;
    font-size: var(--font-size-overline);
    line-height: 1;
    text-transform: none;
    vertical-align: middle;
}

.w-result-summary .ui-result-summary,
.w-result-summary .ui-items-count,
.w-result-summary .ui-pagination {
    float: left;
    margin: 0 10px 0 0;
    line-height: 43px;
    padding: 0;
}

.w-result-summary .ui-pagination {
    margin: 10px 23px 0 0;
}

.w-result-summary .filtering-form {
    float: right;
    width: 40%;
}

.w-result-summary .control-label,
.w-result-summary .k-input,
.w-result-summary .k-dropdown {
    font-size: var(--font-size-overline);
    line-height: 1;
}

.filtering-form .control-label {
    float: right;
    padding: 0 10px;
    line-height: 43px;
    cursor: default;
}

.w-result-summary .k-widget .k-input {
    padding: 0 10px;
    line-height: 43px;
}

.w-result-summary .inline-list {
    display: inline-block;
}

.w-result-summary .k-dropdown {
    float: right;
    border: none;
    border-left: 1px solid var(--grey-300);
    border-right: 1px solid var(--grey-300);
}

.w-result-summary .k-dropdown.date-filter {
    width: 70%;
}

.ui-result-summary {
    color: var(--grey-a700);
}

.ui-result-summary + .ui-items-count {
    margin-right: 12px;
}

.ui-result-summary + .ui-items-count::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 10px;
    background: var(--common-black);
    margin-left: -11px;
    margin-top: 0px;
}

/* #endregion search suggestions and results */

/* #region expandable items */
/* ------------------------------------------------------------------------*/

.expandable {
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
    left: -999em;
    overflow: hidden;
}

.expandable,
.expandable.expanded {
    -webkit-transition: opacity 0.3s linear; /* older Android browser */
    transition: opacity 0.3s linear;
}

.expandable.expanded {
    height: auto;
}

.expandable.animated,
.expandable.expanded {
    opacity: 1;
    visibility: visible;
    position: relative;
    width: auto;
    height: auto;
    left: auto;
}

.expandable.animated {
    display: none;
}

.expandable.expanded.animated {
    opacity: 0;
    display: block;
}

.toggle {
    cursor: pointer;
}

.toggle:hover,
.toggle:focus {
    color: var(--text-hover);
    background-position: right -92px;
}

.toggle.expanded {
    background-position: right -41px;
}

.toggle.expanded:hover,
.toggle.expanded:focus {
    background-position: right -137px;
}

.expanded .toggle {
    background-image: none;
}
/* #endregion expandable items */

/* #region Product list
---------------------------------------------------------------------------*/
.ui-product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, auto);
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    grid-gap: 15px;
    margin-top: 0;
    margin-bottom: 0;
}

.ui-product-list .ui-product-promo-banner,
.ui-product-list .ui-product-box,
.ui-product-list .xui-product-box,
.ui-product-list .flexi-grid-product {
    margin: 0;
    width: 100%;
    height: min-content;
}

.ui-product-box.skeleton .product-box-skel-wrap {
    display: flex;
    flex-direction: column;
}
.ui-product-box.skeleton .product-box-skel-image {
    padding-top: 100%;
}
.ui-product-box.skeleton .product-box-skel-state {
    height: 20px;
    padding: 2px 0px;
}
.ui-product-box.skeleton .product-box-skel-brand {
    height: 15px;
    margin: 10px 0px;
}
.ui-product-box.skeleton .product-box-skel-name {
    height: 38px;
    margin-bottom: 10px;
}
.ui-product-box.skeleton .product-box-skel-price {
    height: 21px;
}

.ui-product-list + .progress-bar {
    margin-top: 30px;
}

.ui-product-list .ui-product-promo-banner:last-child {
    margin-bottom: 0;
}

.ui-product-list .flexi-grid-product .ui-product-box {
    width: 100%;
}

.ui-product-list .ui-product-promo-banner.banner-size2 {
    grid-column-end: span 2;
}

.personal-product-list .ui-product-list {
    margin-bottom: 0;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 2px solid var(--grey-100);
}

.personal-product-list .ui-error-content {
    min-height: 250px;
    margin-top: 30px;
    padding-top: 0;
    border-top: 2px solid var(--grey-100);
}

/* #endregion */

/* #region My pages, Customer care */
/* ------------------------------------------------------------------------*/

.w-side-menu-top + .w-content .page-heading,
.w-menu + .w-content .w-iframe:first-child,
.w-menu + .w-content .iframe-scrollbar-wrapper:first-child {
    margin-top: 30px;
}

.w-menu + .w-content .iframe-scrollbar-wrapper:first-child .w-iframe {
    margin-top: 0;
}

.page-heading {
    text-align: center;
}

/* #endregion */

/* #region registration */
/* ------------------------------------------------------------------------*/

.registration-box-header {
    text-align: center;
    margin-bottom: 35px;
}

.registration-box-header p {
    max-width: 700px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
}

.registration-box-header .intro-text {
    text-align: center;
    margin: 0 auto;
}

/* #endregion registration */

/* #region flags */
.flags {
    display: block;
}

.flags .flag {
    display: inline-block;
    padding: 0 5px;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    line-height: 1.5;
    border: 1px solid var(--grey-900);
    color: var(--text-primary);
    font-weight: bold;
    font-size: var(--font-size-overline);
    border-radius: 5px;
    text-transform: uppercase;
}

.k-ie .flags .flag {
    padding-top: 3px;
}

.ui-basket-products .name .flag .flag {
    margin-top: 3px;
}

/*
List of possible product flags (just for information since the colors are default)
    .claim
    .loyalty
    .free-sample
    .catalogue-offer
    .last-chance
    .until-stock-lasts
    .coming-soon
    .low-stock
*/

.flags .flag.coming-soon,
.flags .flag.backorder,
.flags .flag.out-of-stock-on-stock,
.flags .flag.out-of-stock,
.flags .flag.over-product-sales-limit,
.flags .flag.not-sold,
.flags .flag.sold-out,
.flags .flag.no-longer-for-sale {
    color: #e7352a;
    border-color: #e7352a;
}
/* #endregion flags */

.ui-stock-info-subscribe {
    margin-top: 2px;
}

.ui-stock-info-subscribe.ui-accordion .long-text {
    border: 1px solid #d9d9d9;
    padding: 11px 15px;
}

.ui-stock-info-subscribe .ui-stock-info-subscribe > .title {
    display: none;
}

.ui-stock-info-subscribe-iframe .w-iframe {
    margin: 0;
    padding-right: 1px;
}

.ui-subscribe-to-stock-info .ui-stock-info-subscribe {
    margin-top: 0;
}

/* #region OOS tooltip */
/* ---------------------------------------------------------------------------*/
.oos-popup-content {
    white-space: nowrap;
}

.oos-popup-link {
    cursor: pointer;
    font-size: var(--font-size-caption);
    margin: 0 18px;
}
/* #endregion OOS tooltip */

/* *****************  Cleaning for Page Content; TODO : what does this mean ?  *************************************************************************************** */

/* #region article image */
/* ------------------------------------------------------------------------*/
.page-content-image {
    max-width: 734px;
    position: relative;
    margin: 16px 0px 0px 0px;
}

.page-content-image img {
    width: 734px;
    max-height: 314px;
    position: relative;
}
/* #endregion article image */

/* #region banners and images remastering */

/* TODO - these are still used at few places, discuss with designers and convert to some of new formats */

.image-B .image-B img {
    width: 734px;
    height: auto;
}

.image-D1,
.image-D1 img {
    height: auto;
    width: auto;
    max-width: 734px;
}

.image-D1 {
    max-height: 314px;
    overflow: hidden;
}

.image-D1 img {
    width: 100%;
}

.image-D2,
.image-D2 img {
    width: 350px;
    height: auto;
    max-height: 150px;
}

.image-F,
.image-F img {
    width: 350px;
    height: auto;
    max-height: 312px;
}

.image-H {
    width: auto;
    height: auto;
    min-height: 200px; /* needed for more/less script */
    max-height: 400px; /* needed for more/less script */
}

.image-I,
.image-I img {
    width: 984px;
    height: auto;
    /* max-height: 300px; - commented out to fix pictures in wrong format (too large), until a decision is made about them */
}

.image-K,
.image-K img {
    width: 234px;
    height: auto;
    max-height: 181px;
}

.image-K img {
    width: 100%;
}

.image-mC {
    width: 350px;
    height: auto;
    max-height: 325px;
}

.image-lazy {
    opacity: 0;
}

/* Image ratio - TODO discuss with designers and merge with new formats
-------------------------------------------------------------------------------*/
.image-ratio {
    position: relative;
    overflow: hidden;
    min-height: 1px;
}

.image-ratio::before {
    content: "";
    display: block;
}

.image-ratio img,
.image-ratio video {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    font-size: 0;
}
/* ratio */
.ratio-16-9::before {
    padding-top: 56.25%;
}

.ratio-7-9::before {
    padding-top: 128.5%;
}
/* #endregion banners and images remastering */

/*
    New image formats
    https://xd.adobe.com/spec/683e54dd-fe9c-4d62-575a-810d29194265-1e0f/
*/
.image-format {
    position: relative;
    overflow: hidden;
}

.image-format::before {
    content: "";
    display: block;
    width: 100%;
}

.image-format img,
.image-format video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

@supports (object-fit: cover) {
    .image-format img,
    .image-format video {
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

/* A: hero banner and section page header, ratio 2.63:1 */
.image-format-A::before {
    padding-top: 38.07%;
}

/* B: category and brand header, ratio 3.51:1 */
.image-format-B::before {
    padding-top: 28.48%;
}

/* C-B: article, ratio C 1.756:1 (mob) & B 3.51:1 (desktop) */
.image-format-C-B::before {
    padding-top: 56.94%;
}

@media screen and (min-width: 1025px) {
    .image-format-C-B::before {
        padding-top: 28.48%;
    }
}

/* C, ratio 1.67:1 (10:6) */
.image-format-C::before {
    padding-top: 60%;
}

/* C, ratio 1:1 */
.image-format-D::before {
    padding-top: 100%;
}

/* #endregion new image formats */

/* #region basket login form; TODO : place into forms.css or products.css ? */
/* ------------------------------------------------------------------------*/
.ui-login-form .heading {
    text-transform: uppercase;
}

.ui-mobile-login-form .heading {
    font-size: var(--font-size-caption);
}

.ui-mobile-login-form .w-control {
    margin-bottom: 10px;
}

.ui-mobile-login-form .form-actions {
    margin-top: 15px;
}

.ui-mobile-login-form .login-basket {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
/* #endregion basket login form */

/* #region consultant registration; TODO : move to consultants.css ? */
/*  Consultants my pages Invitation Tool & Online registration pages,
    Customers my pages Become Consultant page,
    Business Oportunity SelfRegistration page
------------------------------------------------------------------------- */

.ui-online-registration-box-iframe .w-iframe {
    margin-left: 13px;
    margin-top: 0;
}

.ui-registration-boxes {
    margin-bottom: 30px;
}

.ui-registration-boxes p {
    margin-top: 0;
}

.ui-registration-boxes .page-table {
    width: 600px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.ui-registration-boxes .page-box-text {
    width: 190px;
    float: right;
    padding: 10px 15px 35px 40px;
    margin-left: 35px;
    border: 1px solid var(--grey-300);
}

.page-editor .ui-info-box .info-image {
    height: 250px;
    border: 1px dashed #d0d0d0;
}

.page-editor .ui-info-box .info-image img {
    width: auto;
    max-width: 100%;
}

.page-editor noscript {
    display: none;
}

.ui-info-box {
    margin-bottom: 15px;
}

.ui-info-box .info-image img {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.ui-info-box .info-body {
    border: 1px solid #d0d0d0;
    padding: 31px;
    vertical-align: middle;
    text-align: center;
}
.ui-info-box .info-image + .info-body {
    border-top-width: 0;
}

.ui-info-box .info-body .heading {
    text-transform: uppercase;
    font-size: var(--font-size-caption);
}

.ui-info-box .info-body .subheading {
    font-family: SansOri, SansOriFallback, sans-serif;
    text-transform: uppercase;
    font-size: var(--font-size-h4);
    margin-bottom: 20px;
}
/* #endregion consultant registration */

/*  Mouse tooltip
------------------------------------------------------------------------- */
.ui-tooltip {
    position: absolute;
    display: block;
    max-width: 228px;
    border: 1px solid var(--grey-300);
    background: var(--common-white);
    padding: 5px 10px;
    font-size: var(--font-size-caption);
    z-index: 6500;
}

.separator {
    height: 1px;
    background: var(--grey-300);
    margin-top: 30px;
    margin-bottom: 30px;
}
/* Headline border
-------------------------------------------------------------------------------*/
.headline-border-holder {
    border-bottom: 1px solid var(--grey-300);
    padding-bottom: 1em;
}
/* Article category
-------------------------------------------------------------------------------*/
.ui-article-category {
    color: var(--text-disabled);
    text-transform: uppercase;
    font-size: var(--font-size-caption);
    line-height: 1.42;
    margin-bottom: 15px;
    display: block;
}

.arrow-hide {
    display: none !important;
}

/* Spinner in buttons
-------------------------------------------------------------------------------*/
.k-button .k-loading-image {
    width: 20px;
    height: 20px;
    left: auto;
    right: 10px;
    margin-left: 0;
    margin-top: -10px;
}

/*
* Border bottom for sticky bar scrolling
*/
.add-border-bottom {
    border-bottom: 1px solid var(--grey-300);
}
/*
* Font color for banners
*/
.font-white {
    color: var(--common-white);
}

.font-black {
    color: var(--text-primary);
}
/* #region dialogue */

/* small modal dialogues styling ( alerts, confirmations, etc. )
   sizing dialogue according to its contents, not from wrapper */
.ori-dialog .k-window-content {
    height: auto;
}

.ori-dialog .k-window-content > .w-buttons {
    position: relative;
    padding: 0;
    margin-top: 10px;
}

.media {
    overflow: auto;
}

.media > .k-icon {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 5px 5px 0;
    vertical-align: middle;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.media > .k-icon::before {
    font-size: 3.8rem;
}

.media > .v-icon-round-info::before {
    top: 54%;
}
/* #endregion dialogue */

/* #region obsolete browsers */
.w-obsolete-browsers {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9500;
    overflow: hidden;
}

.chromeframe {
    margin: 32px auto 0;
    background: var(--grey-300);
    padding: 0.5em 20px;
    font-size: var(--font-size-body1);
    width: 940px;
    position: relative;
}

.chromeframe-browsers {
    margin: 0;
    font-size: var(--font-size-body1);
}

.chromeframe-browsers li {
    list-style-position: inside;
}

.chromeframe .v-icon-cross {
    padding: 0 3px;
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: var(--font-size-icon3);
    line-height: 0.91;
    cursor: pointer;
}

.chromeframe .v-icon-cross:hover {
    color: #ff8c00;
}

.chromeframe .v-icon-cross span {
    display: none;
}

/* #endregion obsolete browsers */

/* #region Common messages */
.ui-message {
    padding: 10px 0;
    font-size: var(--font-size-body1);
    margin: 10px 0;
}

.ui-message .msg {
    padding: 8px 0;
    margin: 0;
}

.ui-message.has-border {
    border: 1px solid var(--grey-300);
    padding: 10px 20px;
    margin: 20px 0;
}

.ui-message.has-icon {
    padding-left: 28px;
    position: relative;
}

.ui-message.has-border.has-icon {
    padding-left: 45px;
}

.ui-message.is-error {
    border-color: #f00;
    color: #f00;
}

.ui-message.has-icon::before {
    font-size: var(--font-size-icon4);
    color: #f00;
    position: absolute;
    top: 15px;
    left: 5px;
}

.ui-message.has-border::before {
    left: 20px;
}

.k-numeric-wrap input[type="number"]::-webkit-inner-spin-button,
.k-numeric-wrap input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
/* #endregion Common messages */

/* #region Show more */
.show-more-wrap {
    overflow: hidden;
    position: relative;
}

.show-more-wrap .show-more-btn {
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    cursor: pointer;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
    background: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
}

.show-more-wrap.expanded .show-more-btn {
    background: none;
}

.show-more-wrap .show-more-text {
    padding-bottom: 5px;
    border-bottom: 2px solid var(--grey-900);
    font-weight: bold;
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    display: block;
    font-size: var(--font-size-caption);
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
}
/* #endregion ShowMore */

/* Youku player */
#youku-player-container {
    position: relative;
    overflow: hidden;
    padding-top: 40px;
}
/* End of Youku player */

/* Experience editor breadcrumb */
/* TODO remove when sitecore fixes the issue */
#breadcrumbMenuSubcontrol_context_menu .sc-breadcrumb {
    max-height: 280px;
    overflow-y: auto !important;
}
/* End of Experience editor breadcrumb */

/* BUG279284 - icons are wrong displayed in PDF */
kendo-pdf-document .k-grid-header .k-icon {
    display: none;
}

/* BP1 */
@media screen and (max-width: 479px) {
    /*
    ===============================================================================
    PAGE MODULES
    ( organized from generic to special ones, from simple to more complex, from
    top to bottom of the page when possible )
    ===============================================================================
    */
    /* Login form
    ---------------------------------------------------------------------------*/
    .w-grid .ui-login-form {
        margin-top: 20px;
    }

    /*  Royal slider
    ---------------------------------------------------------------------------*/
    /* for product sliders etc. the navigation is outside contents */
    .rsWithBullets.nav-outside {
        margin-bottom: 30px;
    }

    .nav-outside .rsNav.rsBullets {
        bottom: -25px;
    }

    /* Registration Consultant
    ---------------------------------------------------------------------------*/
    .ui-info-box {
        display: none;
    }

    .registration-box-header h1.title {
        font-size: var(--font-size-h5);
        margin-bottom: 2px;
    }

    .registration-box-header .intro-text {
        line-height: 18px;
    }

    .ui-online-registration-box-iframe .w-iframe {
        margin-left: 0;
        margin-right: 0;
    }

    /* Headline separator
    -------------------------------------------------------------------------------*/
    .headline-separator {
        font-size: var(--font-size-h5);
    }

    h1 .headline-separator {
        font-size: 2.4rem;
    }
    /* #region banners and images remastering */
    .image-D2,
    .image-D2 img {
        width: auto;
        height: auto;
        max-width: 100%;
    }

    /* Article img without wraper in root of .content
    -------------------------------------------------------------------------------*/
    .ui-page-content .content > img:first-child {
        height: auto !important;
    }

    /* #region OOS popup link */
    .oos-popup-link {
        margin: 0 11px;
    }
    /* #endregion OOS popup link */
}

/* BP2 */

@media screen and (min-width: 480px) and (max-width: 767px) {
    /*
    ===============================================================================
    PAGE MODULES
    ( organized from generic to special ones, from simple to more complex, from
    top to bottom of the page when possible )
    ===============================================================================
    */

    /* Registration Consultant
    ---------------------------------------------------------------------------*/
    .ui-info-box {
        display: none;
    }

    .registration-box-header h1.title {
        font-size: var(--font-size-h5);
        margin-bottom: 2px;
    }

    .registration-box-header .intro-text {
        line-height: 18px;
    }

    .ui-online-registration-box-iframe .w-iframe {
        margin-left: 0;
        margin-right: 0;
    }

    /* Product list
    ------------------------------------------------------------------------- */
    .ui-product-list {
        grid-template-columns: repeat(3, 1fr);
    }

    /*===============================================
    * Article Main Image
    *================================================
    */
    .image-D1 {
        width: 100%;
        text-align: center;
    }

    .image-D1 img {
        max-width: 100%;
        margin: 0 auto;
    }

    .image-I,
    .image-I img {
        width: 768px;
    }

    /* Article img without wraper in root of .content
    -------------------------------------------------------------------------------*/
    .ui-page-content .content > img:first-child {
        height: auto !important;
    }
}

/* BP1 and BP2 */
@media screen and (max-width: 767px) {
    .w-side-menu-top + .w-content .page-heading,
    .w-menu + .w-content .w-iframe:first-child,
    .w-menu + .w-content .iframe-scrollbar-wrapper:first-child {
        margin-top: 10px;
    }

    .w-menu + .w-content .iframe-scrollbar-wrapper:first-child .w-iframe {
        margin-top: 0;
    }

    /* Tabs; TODO : merge with Kendo CSS or remove completely
    ---------------------------------------------------------------------------*/
    .ui-tabs .tab-links,
    .ui-tabs .tab-heading {
        font-size: var(--font-size-h5);
        line-height: 1.15;
    }

    .ui-tabs .tab-heading {
        padding: 0 7px 10px;
    }
}

/* BP3 */

@media screen and (min-width: 768px) and (max-width: 919px) {
    /*
    ===============================================================================
    NO GRID DEFINITION
    size 3 breakpoint currently uses grid from size 4
    ===============================================================================
    */

    /* Article img without wraper in root of .content
    -------------------------------------------------------------------------------*/
    .ui-page-content .content > img:first-child {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* Product list
    ------------------------------------------------------------------------- */
    .ui-product-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* BP4 */
@media screen and (min-width: 768px) {
    /* MyPage News Article
    ------------------------------------------------------------------------- */
    .image-D1,
    .image-D1 img {
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
        display: block;
    }

    /* Article img without wraper in root of .content
    -------------------------------------------------------------------------------*/
    .ui-page-content .content > img:first-child {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* gsa mini search*/
    .suggestions {
        padding: 5px 0;
        border-bottom: 1px solid var(--grey-300);
    }

    .suggestions li {
        padding: 10px;
        font-size: var(--font-size-subtitle1);
        line-height: 0.94;
    }
}

/* BP4 + 5 */
@media screen and (min-width: 920px) {
    .ui-product-list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
    }
}

/* BP5 */

@media only screen and (min-width: 1025px) {
    .htmlf-wrapper.has-sticky-header {
        padding-top: 31px;
        box-sizing: content-box;
    }
    .n-header ~ .htmlf-wrapper.has-sticky-header {
        padding-top: 0;
    }

    .page-header {
        z-index: 5001; /* k-loading has 5000 */
    }
    /*
    ===============================================================================
    overrides for fixed site layout
    ===============================================================================
    */

    /* Default settings for section wrappers
    ---------------------------------------------------------------------------*/
    .page-header {
        height: auto;
    }

    .quick-order .v-icon-editorial-filled {
        font-size: var(--font-size-icon2);
        font-style: normal;
        float: left;
        margin-right: 5px;
    }

    /*  Sticky Filter bar
    ------------------------------------------------------------------------- */
    .w-filter-bar-sticky {
        margin-top: 135px;
    }

    /* Product box
    ---------------------------------------------------------------------------*/

    /* gsa mini search*/
    .w-suggestions {
        margin: 0;
        padding: 0;
    }

    .suggestions {
        padding: 9px 0 8px;
        border-bottom: 1px solid var(--grey-300);
    }

    .suggestions .selected {
        background: var(--grey-100);
    }

    .suggestions li {
        padding: 3px 10px;
        font-size: var(--font-size-caption);
        line-height: 1.33;
    }

    .ui-product-replacement #cboxLoadedContent {
        width: 790px;
    }
}


/* React Apps shared skeleton animation
---------------------------------------------------------------------------*/

.skeleton:empty {
    background-color: #F6F6F7;
    animation: shine-lines 2s infinite linear;
    background-image: linear-gradient(90deg, #F6F6F7 0px, #D9D9D9 25%, #F6F6F7 50%);
    background-size: 50% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

@keyframes shine-lines {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 200%;
    }
}